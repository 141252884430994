import { map } from 'lodash';
import CONSTANT_FRIEND from '~/utils/constant/friend';
import {
  acceptFriendRequest,
  applyFriend,
  rejectFriendRequest,
  getFriendRequestList,
  searchMembers,
  getFriendList,
  removeFriend,
  getFriendInfo,
} from '~/api-tc/friend';

const state = () => ({});

const mutations = {
};

const actions = {
  actionQueryFriendList: async ({ commit }, payload) => {
    const { data } = await searchMembers(payload);
    return data;
  },
  actionGetFriendList: async ({ commit }, payload) => {
    const { data } = await getFriendList({
      status: CONSTANT_FRIEND.relationship.DEFAULT,
      ...payload,
    });
    return map(data, (value) => ({
      betLevel: value.friendBetLevel,
      code: value.friendCode,
      headImage: value.friendHeadImage,
      isOnline: value.isOnline,
      memberId: value.friendMemberId,
      nickname: value.friendNickName,
      username: value.friendUsername,
    }));
  },
  actionGetFriendRequestList: async ({ commit }) => {
    const { data } = await getFriendRequestList();
    return map(data, (value) => ({
      betLevel: value.requesterBetLevel,
      code: value.requesterCode,
      headImage: value.requesterHeadImage,
      memberId: value.requesterId,
      nickname: value.requesterNickName,
      username: value.requesterUsername,
    }));
  },
  actionApplyFriend: async (_, payload) => {
    const { data } = await applyFriend(payload);
    return data;
  },
  actionAcceptFriendRequest: async (_, payload) => {
    const { data } = await acceptFriendRequest(payload);
    return data;
  },
  actionRejectFriendRequest: async (_, payload) => {
    const { data } = await rejectFriendRequest(payload);
    return data;
  },
  actionDeleteFriend: async (_, payload) => removeFriend(payload),
  actionGetFriendInfo: async (_, payload) => getFriendInfo(payload),
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
