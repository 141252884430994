import { forEach, isArray, isEmpty, isUndefined, reduce, some } from 'lodash';
import { differenceInMinutes } from 'date-fns';
import {
  getBonusMessages,
  getChatRoomList,
  getOtherMemberInfo,
  joinChatRoom,
} from '~/api-tc/member';
import CONSTANT_CHAT from '~/utils/constant/chat';

const state = () => ({
  roomList: {},
  messages: {},
  lastUpdatedTime: {},
  lastSeenTime: {},
  latestMsgList: [], // 遊戲內新訊息
  latestBonusNotifyList: [], // 大廳公告跑馬燈
  lastMessageInfo: {
    roomName: null,
    memberId: null,
  },
  otherMemberInfo: {
    nickname: '',
    headImage: 'default',
    rankLevel: 1,
    betLevel: 1,
    introduction: '',
    cash: 0,
    point: 0,
  },
});

const mutations = {
  SET_ROOM_LIST: (state, data) => {
    state.roomList = data;
    if (isEmpty(data)) return;

    const now = Date.now();
    forEach([...data.system, ...data.lobby], (roomName) => {
      state.lastUpdatedTime[roomName] = now;
      state.lastSeenTime[roomName] = now;
    });
  },
  APPEND_ROOM_MESSAGE: (state, { roomName, message, receiveType }) => {
    if (isUndefined(state.messages[roomName])) {
      state.messages[roomName] = [];
    }

    const now = Date.now();
    const formatedMsg = reduce(
      isArray(message) ? message : [message],
      (accu, msg) => {
        const value = {
          ...msg,
          addTime: msg.addTime ?? now,
        };

        switch (roomName) {
          case CONSTANT_CHAT.roomName.LOBBY:
            if (msg?.content && msg.headImage) {
              accu.push(value);
            }
            break;
          default:
            accu.push(value);
            break;
        }
        return accu;
      },
      [],
    );

    // 新訊息(通知用)
    switch (roomName) {
      case CONSTANT_CHAT.roomName.NOTIFY_BONUS:
        if (receiveType === CONSTANT_CHAT.receiveType.NEW) {
          state.latestBonusNotifyList.push(...formatedMsg);
        }
        break;
      case CONSTANT_CHAT.roomName.LOBBY:
      default:
        state.latestMsgList.push(...formatedMsg);
        state.latestMsgList = state.latestMsgList.slice(
          state.latestMsgList.length - 100,
          state.latestMsgList.length,
        );
    }

    // 歷史訊息最多存100筆
    if (!state.messages[roomName]) state.messages[roomName] = [];
    state.messages[roomName].push(...formatedMsg);
    state.messages[roomName] = state.messages[roomName].slice(
      state.messages[roomName].length - 100,
      state.messages[roomName].length,
    );
    state.lastMessageInfo = {
      roomName,
      memberId: formatedMsg[formatedMsg.length - 1]?.memberId ?? null,
    };

    Object.assign(state, {
      lastUpdatedTime: {
        ...state.lastUpdatedTime,
        [roomName]: now,
      },
    });
  },
  POP_BONUS_NOTIFY: (state, deleteCount) => {
    state.latestBonusNotifyList.splice(0, deleteCount ?? 1);
  },
  SET_OTHER_MEMBER_INFO: (state, data) => {
    state.otherMemberInfo = data;
  },
  CLEAR_HISTORY: (state, roomName) => {
    state.messages[roomName] = [];
  },
  UPDATE_LAST_SEEN_TIME: (state, roomName) => {
    Object.assign(state, {
      lastSeenTime: {
        ...state.lastSeenTime,
        [roomName]: Date.now(),
      },
    });
  },
  SPLICE_LATEST_MSG_LIST: (state, payload) => {
    state.latestMsgList.splice(...payload);
  },
};

const actions = {
  init: async ({ commit, dispatch, getters }) => {
    commit('SET_ROOM_LIST', {});
    await dispatch('actionGetRoomList');
    if (getters.roomList[0]?.name) {
      dispatch('actionJoinRoom', {
        roomName: CONSTANT_CHAT.roomName.LOBBY,
      });
    }
  },
  actionGetRoomList: async ({ commit }) => {
    const { data } = await getChatRoomList().catch(() => ({ data: {} }));
    commit('SET_ROOM_LIST', data);
    return data;
  },
  actionAppendMessage: async ({ commit, dispatch, rootGetters }, data) => {
    switch (data.roomName) {
      case CONSTANT_CHAT.roomName.NOTIFY_BONUS:
        if (!rootGetters['initData/isBonusNotifyEnable']) {
          return;
        }

        data.message = await dispatch('actionGetGameInfo', data.message);
        break;
      case CONSTANT_CHAT.roomName.LOBBY:
      default:
        break;
    }
    commit('APPEND_ROOM_MESSAGE', data);
  },
  actionGetGameInfo: async ({ dispatch }, data) => {
    const response = await Promise.allSettled(
      data.map(async (message) => {
        const gameInfo = await dispatch(
          'game/actionQueryGameInfo',
          {
            gameId: `${message.gameId}`,
            size: '500x500',
          },
          {
            root: true,
          },
        );
        return {
          ...message,
          ...gameInfo,
        };
      }),
    );
    return response.map((item) => item.value);
  },
  actionPopBonusNotify: ({ commit }, data) => commit('POP_BONUS_NOTIFY', data),
  actionGetOtherMemberInfo: async ({ commit }, payload) => {
    const result = await getOtherMemberInfo(payload).catch((error) => {
      console.log(error);
    });
    if (!result) return null;

    commit('SET_OTHER_MEMBER_INFO', result.data);
    return result;
  },
  actionJoinRoom: async ({ commit, state }, payload) => {
    switch (payload.roomName) {
      case CONSTANT_CHAT.roomName.NOTIFY_BONUS: {
        if (state.messages[payload.roomName]?.length) {
          return state.messages[payload.roomName];
        }

        const { data } = await getBonusMessages();
        commit('APPEND_ROOM_MESSAGE', {
          roomName: payload.roomName,
          message: data,
        });
        commit('UPDATE_LAST_SEEN_TIME', payload.roomName);
        return data;
      }
      default: {
        const result = await joinChatRoom(payload).catch((error) => {
          console.log(error);
        });
        if (!result) return null;

        return result;
      }
    }
  },
  actionClearAllHistory: ({ dispatch, getters }, forceClear = false) => {
    forEach(getters.roomList, (room) => {
      dispatch('actionClearHistory', { roomName: room.name, forceClear });
    });
  },
  actionClearHistory: ({ commit, state }, { roomName, forceClear = false }) => {
    const lastUpdatedTime = state.lastUpdatedTime[roomName];
    if (!forceClear) {
      if (
        !lastUpdatedTime ||
        differenceInMinutes(Date.now(), lastUpdatedTime) < 30
      ) {
        return;
      }
    }

    commit('CLEAR_HISTORY', roomName);
  },
  actionSetLastSeenTime: ({ commit }, payload) => {
    commit('UPDATE_LAST_SEEN_TIME', payload);
  },
  actionSpliceLatestMsgList: ({ commit }, payload) => {
    commit('SPLICE_LATEST_MSG_LIST', payload);
  },
  actionGetBonusNotify: async ({ dispatch, rootGetters }) => {
    if (!rootGetters['initData/isBonusNotifyEnable']) {
      return;
    }

    dispatch('actionClearHistory', {
      roomName: CONSTANT_CHAT.roomName.NOTIFY_BONUS,
      forceClear: true,
    });
    dispatch('actionJoinRoom', {
      roomName: CONSTANT_CHAT.roomName.NOTIFY_BONUS,
    });
  },
};

const getters = {
  roomList: (state, _getters, _rootState, rootGetters) => {
    let list = [];

    if (rootGetters['initData/isBonusNotifyEnable']) {
      list.push({
        name: CONSTANT_CHAT.roomName.NOTIFY_BONUS,
        type: CONSTANT_CHAT.roomType.NOTIFY_BONUS,
      });
    }

    forEach(CONSTANT_CHAT.roomType, (type) => {
      forEach(state.roomList[type], (name) => {
        list.push({ name, type });
      });
    });
    return list;
  },
  hasNewMsg: (state) =>
    some(
      state.lastUpdatedTime,
      (updatedTime, roomName) =>
        differenceInMinutes(Date.now(), updatedTime) < 30 &&
        (!state.lastSeenTime[roomName] ||
          updatedTime > state.lastSeenTime[roomName]),
    ),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
